.media-overview-card {
  &.website-template-overview-card {
    .media-overview-card__cover-block {
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;
      height: max-content;
      padding-bottom: 0;
    }

    .cover-content {
      object-fit: contain;
      object-position: top;
      opacity: 0.5;

      &_main {
        opacity: 1;
        border: 3px solid $brand-primary;
        padding: 2px;
      }
    }
  }
}
