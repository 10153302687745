.selected-list-control {
  display: flex;
  flex-direction: column;
  background-color: $brand-neutral;
  padding: 20px;

  &__header {
    margin-bottom: 0.75rem;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__body {
  }

  &__list-wrap {
    height: 74px;

    @media (max-width: 767.98px) {
      height: 81px;
    }
  }

  &__item-block {
  }

  &__item {
    display: flex;
  }

  &__item-remove {
  }

  &__item-remove-icon {
    @media (max-width: 767.98px) {
      padding-top: 2px;
    }
  }

  &__item-content-block {
    display: flex;
    margin-left: 0.5rem;
    width: 100%;
  }

  &__item-text {
    word-break: break-all;
  }
}
