.tabs-nav {
  .nav-item {
  }

  .nav-link {
    color: $custom-gray;

    &:hover,
    &:focus {
      color: $brand-secondary;
      text-decoration: none;
    }
  }

  &.nav-pills .nav-link.active,
  &.nav-pills .show > .nav-link {
    color: #fff;
    background-color: $brand-secondary;
  }

  &.nav-pills .nav-link {
    background: none;
    padding: calc(0.47rem - 1px) 1rem;
    border-radius: 0.125rem;
    border: 1px solid transparent;
    font-weight: normal;

    &:not(.active) {
      border: 1px solid #d1dad9;
    }
  }
}
