.icon-btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 1rem;
  font-size: 16px;

  &_bg-secondary {
    background-color: $brand-secondary;
  }
  &_bg-primary {
    background-color: $brand-primary;
  }
  &_bg-dark {
    background-color: $brand-dark;
  }
  &_bg-danger {
    background-color: $brand-danger;
  }
  &_bg-white {
    background-color: $white;
  }

  &_text-white {
    color: $custom-white;
  }
  &_text-danger {
    color: $brand-danger;
  }

  .mdi {
    position: relative;
    top: 1px;
  }
}
