.media-overview-card {
  width: 100%;

  &__cover-block {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 500px;
    padding-bottom: 0.5rem;
  }

  .cover-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  &__badges-block {
    position: absolute;
    top: 0.75rem;
    left: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__actions-block {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__header-panel {
  }

  &__title-block {
    padding: 0.5rem 0 0;
  }

  &__title {
    font-weight: 700;
    font-size: 15px;
  }

  &__body {
    padding: 0.5rem 0 0;
  }

  &__description {
    .read-more-expand__text {
      font-size: 13px;
    }
  }

  &_size {
    &_sm {
      .media-overview-card__cover-block {
        height: 272px;
        @media (max-width: 767.98px) {
          height: 100%;
        }
      }

      .media-overview-card__description {
        .read-more-expand__text {
          font-size: 11px;
        }
      }
    }
  }
}
