.read-more-expand {
  &__text {
    display: flex;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
  }

  &__actions {
    margin-top: 0.5rem;
  }

  &__btn {
    font-size: 13px;
    color: $brand-secondary;

    &:hover,
    &:focus {
      color: $brand-secondary;
    }
  }
}
