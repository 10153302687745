.wysiwyg-editor {

}

.wysiwyg-editor-wrapper {
  width: 100%;
  background: $white;
}

.wysiwyg-editor-editor {
  background: $white;
  border: 1px solid $gray-300;
  height: 239px !important;
  padding-right: 15px;

  &_document-text {
    font-family: $text-editor-doc-font-family;
    color: #000000;
  }
}