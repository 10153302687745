.website-panel-models {
  .website-panel-models-grid {
    .grid__row {
      margin-left: -0.25rem;
      margin-right: -0.25rem;
    }

    .grid__col {
      width: calc(100% / 4);
      margin-bottom: 1.5rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;

      @media (max-width: 1399.98px) {
        width: calc(100% / 3);
      }

      @media (max-width: 767.98px) {
        width: calc(100% / 2);
      }
    }

    .grid__item {
      height: 100%;
      display: flex;
    }
  }
}
