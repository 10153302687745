.website-upload-avatar {
  display: flex;

  &__block {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    img {
      max-width: 100%;
      height: auto;
      object-position: top;
    }
  }

  &__select-block {
    display: flex;
    margin-bottom: 8px;

    .avatar-select-wrap {
      display: flex;

      .user-photo-avatar__block {
        justify-content: center;
        width: 260px;
        height: 160px;
        img {
          width: 260px;
          height: 160px;
        }
        .img-cover {
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
