.client-general {
  .client-general-info {
    .card-logo-photo {
      margin-right: 1rem;

      img {
        max-width: 100%;
        height: auto;

        @media (max-width: 575.98px) {
          width: 100%;
        }
      }

      &__block {
        display: flex;
        width: 105px;
        height: 105px;
        position: relative;
        @media (max-width: 575.98px) {
        }
      }

      &__placeholder-block {
        background-color: $brand-light;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
          width: 64px;
          height: 64px;
        }
      }

      &__edit-block {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }
    }
  }

  .info-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    &__label {
      font-weight: bold;
      white-space: nowrap;
      justify-self: end;
      margin-right: 5px;
    }

    &__info {
      white-space: nowrap;
      text-align: justify;
    }
  }
}
