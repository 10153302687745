@mixin for-size($size) {
  @if $size == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size == tablet {
    @media (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

$color-small: $custom-green;
$color-medium: $brand-primary;
$color-large: $brand-secondary;

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pricing-block {
  &__panel {
    &_active {
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.5s;
    }
  }

  &__card-block {
    position: relative;
    width: 100%;
    max-width: 320px;
    border-radius: 10px;
    padding-bottom: 25px;
    border: 2px solid $custom-white;
    background-color: $custom-white;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease-in-out;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    &:not(:nth-child(3n)) {
      margin-right: 45px;
    }
  }

  &__card-overhead {
    position: relative;
    display: block;
    text-align: center;
    width: calc(100% + 4px);
    margin-top: -2px;
    margin-left: -2px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    color: $custom-white;
    border-radius: 10px 10px 0 0;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-bottom: 22px;

    @media (max-width: 767.98px) {
      font-size: 18px;
    }
  }

  &__card-block.small &__card-overhead {
    background-color: $color-small;
  }

  &__card-block.medium &__card-overhead {
    background-color: $color-medium;
  }

  &__card-block.large &__card-overhead {
    background-color: $color-large;
  }

  &__card-overhead-icon {
    position: absolute;
    left: 50%;
    margin-left: -9px;
    bottom: -8px;
    display: block;
    fill: $color-small;
    transition: all 0.5s ease-in-out;
  }

  &__card-block.medium &__card-overhead-icon {
    fill: $color-medium;
  }

  &__card-block.large &__card-overhead-icon {
    fill: $color-large;
  }

  &__card-block.medium .tariff-btn--select svg {
    stroke: $color-medium;
  }

  &__card-block.large .tariff-btn--select svg {
    stroke: $color-large;
  }

  &__card-price {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    font-size: 65px;
    font-weight: 500;
    color: #354052;
    letter-spacing: -0.03em;
    line-height: 1.1;

    span.currency {
      display: block;
      font-size: 25px;
      margin-right: 6px;
    }
  }

  &__card-price-desc {
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.7;
    margin-bottom: 8px;
  }

  &__card-billing {
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.7;
    margin-bottom: 20px;
  }

  &__card-free-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
    height: 40px;
    margin: 0 auto;
    border-radius: 8px;
    line-height: 1.46;
    font-size: 15px;
    font-weight: 500;
    color: #354052;
    border: 2px solid #354052;
    margin-bottom: 16px;
    transition: all 0.5s ease-in-out;

    @media (max-width: 767.98px) {
      font-size: 17px;
    }
  }

  &__card-block.small:hover &__card-free-block {
    background-color: $color-small;
    border-color: $color-small;
    color: $custom-white;
    text-decoration: none;
  }

  &__card-block.medium:hover &__card-free-block {
    background-color: $color-medium;
    border-color: $color-medium;
    color: $custom-white;
    text-decoration: none;
  }

  &__card-block.large:hover &__card-free-block {
    background-color: $color-large;
    border-color: $color-large;
    color: $custom-white;
    text-decoration: none;
  }

  &__card-bonus {
    display: block;
    text-align: center;
    width: 100%;
    max-width: 180px;
    margin: 0 auto;
    color: $color-small;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.4;

    @media (max-width: 767.98px) {
      font-size: 17px;
    }
  }

  &__card-content-box {
    position: relative;
    display: block;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  &__card-wrapper {
    position: relative;
    display: block;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 16px;
    margin-bottom: 16px;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -140px;
      bottom: 0;
      width: calc(100% - 70px);
      height: 1px;
      background-color: #ebf0fb;
    }
  }

  &__card-list {
    display: block;
  }

  &__card-list ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 19px;
  }

  &__card-list ul li {
    position: relative;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: #354052;
    line-height: 1.2;

    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: -19px;
      width: 9px;
      height: 8px;
      background-image: url("../../../../../assets/images/dev/icons/tariff-card-checked.svg");
      background-repeat: no-repeat;
      background-size: 9px 8px;
      z-index: 10;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__card-bottom {
    position: relative;
    display: block;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 20px;
  }

  &__card-inner-title {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: $brand-secondary;
    margin-bottom: 12px;

    @media (max-width: 767.98px) {
      font-size: 17px;
    }
  }

  &__card-block .tariff-btn--select {
    margin: 0 auto;
    margin-top: auto;
  }

  &__card-block.medium .tariff-btn--select {
    background-color: $color-medium;
  }

  &__card-block.large .tariff-btn--select {
    background-color: $color-large;
  }

  &__card-block.active.small {
    border: 2px solid $color-small;
  }

  &__card-block.active.medium {
    border: 2px solid $color-medium;
  }

  &__card-block.active.large {
    border: 2px solid $color-large;
  }

  &__card-block.active .tariff-btn--select span.active {
    display: none;
  }

  &__card-block.active .tariff-btn--select span.disabled {
    display: block;
  }

  &__card-block.active .tariff-btn--select svg {
    display: block;
  }
}

.tariff-btn {
  display: block;
  text-align: center;
  border-radius: 4px;
  color: $custom-white;

  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;

  line-height: 1;

  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;

  transition: all 0.2s ease-in-out;
  cursor: pointer;

  @media (max-width: 767.98px) {
    font-size: 18px;
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &:active,
  &:focus,
  &:hover {
    color: $custom-white;
    text-decoration: none !important;
    outline: none !important;
  }
}

.tariff-btn--select {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 280px;
  height: 40px;
  border-radius: 8px;
  background-color: $color-small;
  transition: all 0.5s ease-in-out;

  svg {
    display: none;
    margin-right: 6px;
    stroke: $color-small;
    transition: all 0.5s ease-in-out;
  }

  svg > rect:first-child {
    fill-opacity: 100% !important;
  }

  span.active {
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.46;
    color: $custom-white;
    transition: all 0.5s ease-in-out;

    @media (max-width: 767.98px) {
      font-size: 17px;
    }
  }

  span.disabled {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  &_disabled {
    background-color: darkgray !important;

    svg {
      stroke: darkgray !important;
    }
  }
}

@include for-size(mobile) {
  .pricing-block {
    &__card-block {
      margin-bottom: 30px;
    }

    &__card-block:not(:nth-child(3n)) {
      margin-right: 0;
    }

    &__control-block {
      //margin-bottom: 45px;
    }

    .tariff-btn--select {
      max-width: 240px;
    }
  }
}

@include for-size(tablet) {
  .pricing-block {
    &__card-block {
      margin-bottom: 20px;
    }

    &__card-block:first-child {
      margin-right: 20px;
    }

    &__card-block:not(:first-child) {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
}
