.wrapper {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  animation: appear 1s;
}

/* skeleton animation */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.message {
  text-align: center;
}

.icon {
  align-self: center;
  font-size: 40px;
  //display: inline-block;
}
