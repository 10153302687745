.website-request-info {
  .info-list__space {
    flex-grow: 1;
  }
  .info-list__label {
    flex-shrink: 0;
  }
  .info-list__info {
    flex-shrink: 0;
  }
  .image-block {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    height: 100%;
    width: 50%;
    padding-bottom: 0.5rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .cover-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}
