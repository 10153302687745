.video-overview-card {
  width: 100%;

  &__video-player-block {
    min-height: 100px;
    position: relative;
    display: flex;
  }

  &__actions-block {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__title-block {
    padding: 0.5rem 0 0;
  }

  &__title {
    font-weight: 700;
    font-size: 15px;
  }

  &__body {
    padding: 0.5rem 0 0;
  }

  &__description {
    .read-more-expand__text {
      font-size: 13px;
    }
  }

  .iframe-video-player {
    overflow: hidden;
    position: relative;
    width:100%;
  }

  .iframe-video-player::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .iframe-video-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}