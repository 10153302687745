.model-edit-photo-drawer {
  .edit-photo-form {
    .model-photo {
      position: relative;
      max-width: 420px;

      img {
        max-width: 100%;
      }
    }
  }
}