.files-uploader-field {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;

  &__block {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__files-block {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  &__file-col {
    width: calc(100% / 4);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    //height: 100%;
    margin-bottom: 0.5rem;

    @media (max-width: 1399.98px) {
      width: calc(100% / 3);
    }

    @media (max-width: 991.98px) {
      width: calc(100% / 2);
    }

    @media (max-width: 767.98px) {
      width: 100%;
    }
  }

  &__file-item {
    display: flex;
    height: 100%;
  }

  &__picker {
    margin: 1rem 0;
  }

  &__picker-btn {
    font-weight: 500;
    color: $brand-dark;

    &:hover,
    &:focus {
      color: $brand-dark;
    }
  }

  &__picker-btn-icon {
    padding-right: 1rem;
  }
}

.files-uploader-file {
  height: 100%;
  width: 100%;

  &__block {
    width: 100%;
  }

  .card {
    height: 100%;
  }
}
