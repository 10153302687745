.modal-notification {
  .calendar-event-info {
    display: flex;
    flex-direction: column;
    color: $custom-gray-500;
    gap: 0.5rem;

    &__event-type {
      color: $custom-white;
      align-self: flex-start;
      padding: 4px;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }

    &__date-block {
      background: $custom-gray-200;
      color: $custom-white;
      padding: 10px 5px;
      font-family: $event-data-font-family;

      .date-number {
        font-size: 40px;
        line-height: 40px;
        font-weight: bold;
      }
      .separator {
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        align-self: center;
      }
      .date-text {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
      }
    }
    &__other-event-details {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 14px;
        font-weight: bold;
      }
      .divider {
        margin: 0.5rem 0;
      }
      .info-item {
        display: flex;
        gap: 0.5rem;

        &__label {
          font-size: 14px;
          font-weight: bold;
        }
        &__value {
        }
      }
    }
  }
}
