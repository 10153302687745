.book-picked-photos-sidebar-content {
  .book-picked-photos {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &__grid {
      display: grid;
      grid-template-columns: 75%;
      justify-content: center;
    }
  }
}
