$note-color: #b7aeae;
$out-color: #555555;
$scouting-color: #861388;
$go_see-color: #ea95cd;
$casting-color: #83aa5d;
$fitting-color: #4c99e5;
$option-color: #e1e100;
$job-color: #ea513f;
$event-text-color: white;
$other-category-event-color: #6667ab;
$holiday-category-event-color: #ed9132;

:export {
  noteBg: $note-color;
  outBg: $out-color;
  scoutingBg: $scouting-color;
  go_seeBg: $go_see-color;
  castingBg: $casting-color;
  fittingBg: $fitting-color;
  optionBg: $option-color;
  jobBg: $job-color;
  textColor: $event-text-color;
  otherCategoryEventBgColor: $other-category-event-color;
  holidayCategoryEventBgColor: $holiday-category-event-color;
}
