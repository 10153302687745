$gutter: 8;

.model-panel-photos-grid {
  .grid-item,
  .grid-sizer {
    margin-bottom: #{$gutter}px; // gutter
    width: masonry-grid-item-width(4, $gutter);

    @media (max-width: 1399.98px) {
      width: masonry-grid-item-width(3, $gutter);
    }

    @media (max-width: 767.98px) {
      width: masonry-grid-item-width(2, $gutter);
    }

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .model-photo-card {
    width: 100%;

    &__photo-block {
      position: relative;
      display: flex;
      overflow: hidden;
      min-height: 110px;

      a {
        width: 100%;
      }
    }

    &__photo-img {
      width: 100%;
    }

    &__avatar-badge-block {
      position: absolute;
      top: 0.75rem;
      left: 0.5rem;
    }

    &__avatar-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-user-select: none;
      user-select: none;
      background-color: $brand-secondary;
      border: none;
      padding: 0;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      line-height: 1rem;
      font-size: 14px;
      color: #ffffff;
    }

    &__actions-block {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      flex-wrap: wrap;
      width: 100%;
      top: 0;
      right: 0;
      padding: 0.75rem;
      align-items: flex-end;
      align-content: flex-end;
    }

    &__title-block {
      padding: 0.5rem 0 0;
    }

    &__title {
      font-weight: 700;
      font-size: 15px;
    }

    &__body {
      padding: 0.5rem 0;
    }

    &__description {
      .read-more-expand__text {
        font-size: 13px;
        //line-height: 16px;
        //max-height: 64px;
      }
    }
  }
}
