.models-drawer-grid {
  margin-top: 1rem;
  @media (min-width: 768px) {
    margin-left: 1rem;
  }

  .grid__row {
  }
  .grid__col {
    width: calc(100% / 4);
    margin-bottom: 1.5rem;

    @media (max-width: 1699.98px) {
      width: calc(100% / 3);
    }

    @media (max-width: 1599.98px) {
      width: calc(100% / 3);
    }

    @media (max-width: 1140px) {
      width: calc(100% / 2);
    }

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .grid__item {
    height: 100%;
    display: flex;
  }
}
