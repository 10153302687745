.rating-panel-employee {
  .rating-grid {
    .grid__col {
      width: 100%;
      margin-bottom: 1.5rem;
    }

    .grid__item {
      height: 100%;
      display: flex;
    }
  }

  .rating-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    box-shadow: none;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }

    &__icon-block {
      @media (max-width: 575.98px) {
        display: flex;
        justify-content: center;
      }
    }

    &__content-block {
      display: flex;
      flex-direction: column;
      margin-left: 1.25rem;
      width: 100%;

      @media (max-width: 575.98px) {
        margin-left: 0;
        margin-top: 0.75rem;
      }
      &.centered {
        justify-content: center;
      }
    }

    &__title-block {
      display: flex;

      @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__label {
      font-size: 16px;
      font-weight: 700;
      margin-right: 1rem;

      @media (max-width: 767.98px) {
        font-size: 18px;
      }

      @media (max-width: 575.98px) {
        margin-right: 0;
      }
    }

    &__date {
      font-size: 15px;
      color: $brand-dark;

      @media (max-width: 767.98px) {
        font-size: 17px;
      }
    }

    &__text {
      margin-top: 0.75rem;
      font-size: 15px;

      @media (max-width: 767.98px) {
        font-size: 17px;
      }
    }

    &__author {
      font-size: 15px;
      font-weight: 700;
      margin-top: 0.75rem;

      @media (max-width: 767.98px) {
        font-size: 17px;
      }
    }

    &__remove-block {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}
