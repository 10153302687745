.cropped-avatar-output {
  position: relative;
  overflow: hidden;
}

.cropped-avatar-output img {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}

.image-crop-control {
  .cropped-canvas-preview {
    object-fit: contain;
    &__square {
      width: 250px;
      height: 250px;
      @media (max-width: 992px) {
        width: 200px;
        height: 200px;
      }
    }
    &__rectangle {
      width: 300px;
      height: 300px;
      @media (max-width: 992px) {
        width: 200px;
        height: 200px;
      }
    }
  }
}
