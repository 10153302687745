.agencies-panel-sidebar {
  .agencies {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;

    & .agency-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      &__name {
        color: $brand-dark;
        font-style: normal;
        font-weight: normal;
        line-height: 18px;
        text-wrap: normal;
        margin-left: 10px;

        &:hover,
        &:active {
          color: $brand-secondary;
        }

        &_active {
          color: $brand-secondary;
        }
      }
    }
  }
}
