.rating-form {
  .rating-radio-group {
    padding: 0 0.75rem;
    flex-wrap: wrap;
  }
}

.rating-radio-group {
  display: flex;
  justify-content: space-between;
}

.rating-radio {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;

  @media (max-width: 991.98px) {
    flex: 1 0 30%;
  }

  @media (max-width: 575.98px) {
    flex: 1 0 50%;
  }

  &__icon-label {
    cursor: pointer;
  }

  &__control {
    display: flex;
  }

  &__input {
    cursor: pointer;

    &:checked {
      border-color: $brand-secondary;
      background-color: $brand-secondary;

      &:after {
        color: $brand-secondary;
      }
    }
  }

  &__label {
    cursor: pointer;
    padding-left: 0.5rem;
  }
}
