.grid {
  .gridRow {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .gridCol {
    width: calc(100% / 4);
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (max-width: 1399.98px) {
      width: calc(100% / 4);
    }

    @media (max-width: 1199.98px) {
      width: calc(100% / 3);
    }

    @media (max-width: 991.98px) {
      width: calc(100% / 3);
    }

    @media (max-width: 767.98px) {
      width: calc(100% / 2);
    }

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }
}