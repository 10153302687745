.agency-statement {
  padding-bottom: 60px;

  .statement-table-total {
    text-align: right;

    @media (max-width: 767.98px) {
      text-align: left;
    }
  }

  .statement-payment-action {
    &__btn {
      min-width: 5.5rem;
    }
  }

  @media print {
    .info-card {
      box-shadow: none;
      padding: 0;
      background: $custom-white;
    }

    table {
      thead {
        background-color: $custom-white;
      }

      th {
        color: $custom-black;
      }
    }
  }
}
