.package-data-tabs {
  &__nav-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 575.98px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}