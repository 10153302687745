@mixin for-size($size) {
  @if $size == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size == tablet {
    @media (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

.pricing-block {
  &__control-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    //margin-bottom: 32px;

    label {
      display: block;
      font-size: 15px;
      font-weight: 400;
      color: $custom-gray-400;
      margin: 0;
      transition: all 0.5s ease-in-out;

      @media (max-width: 767.98px) {
        font-size: 17px;
      }
    }

    label:not(:last-child) {
      margin-right: 14px;
    }
  }

  &__label--annually.active {
    color: $brand-primary;
  }

  &__label--monthly.active {
    color: $brand-primary;
  }

  &__checkbox-block {
    display: block;
    width: 54px;
    height: 32px;
    margin-right: 14px;
  }
}

.pricing-block__checkbox-block input[type="checkbox"] {
  display: none;
}

.pricing-block__checkbox-block input[type="checkbox"] + label {
  cursor: pointer;
  position: relative;
  line-height: 40px;
}

.pricing-block__checkbox-block input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  width: 54px;
  height: 32px;
  vertical-align: middle;
  border-radius: 60px;
  background-color: $brand-neutral;
}

.pricing-block__checkbox-block input[type="checkbox"] + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: $brand-primary;
  background-image: url("../../../../../assets/images/dev/icons/tariff-period-checked.svg");
  background-repeat: no-repeat;
  background-size: 12px 11px;
  background-position: center center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
}

.pricing-block__checkbox-block input[type="checkbox"] + label::before,
.pricing-block__checkbox-block input[type="checkbox"] + label::after {
  transition: all 0.2s ease-out;
}

.pricing-block__checkbox-block input[type="checkbox"]:checked + label::after {
  left: 27px;
}

.pricing-block__checkbox-block input[type="checkbox"]:checked + label,
.pricing-block__checkbox-block input[type="checkbox"]:not(:checked) + label {
  margin-left: 0;
}

.pricing-block__checkbox-block
  input[type="checkbox"]:not(:checked)
  + label::after {
  opacity: 1;
}

@include for-size(mobile) {
  .pricing-block {
  }
}

@include for-size(tablet) {
  .pricing-block {
  }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
}
