@import "src/assets/scss/giulia-variables";

.list {
  padding-left: 1rem;

  ::marker {
    content: counter(list-item) ". ";
    color: $brand-primary;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
  }

  li {
    margin-bottom: 0.5rem;
  }
}