.panel-menu {
  background-color: transparent;
  border: none;
  font-weight: 500;

  .rc-menu-item,
  .rc-menu-submenu {
    border: none;

    a {
      color: $brand-secondary;
    }
  }

  .rc-menu-item.rc-menu-item-active:not(.rc-menu-item-selected) {
    a:hover {
      box-shadow: inset 0 -2px $brand-secondary-light;
    }
  }

  .rc-menu-item.rc-menu-item-selected {
    a {
      box-shadow: inset 0 -2px $brand-primary;
      color: $brand-primary;
    }
  }

  .rc-menu-item-selected {
    background-color: transparent;
  }

  .rc-menu-submenu-active,
  .rc-menu-item-active {
    border: none;
    background-color: transparent;
    color: $brand-secondary;
  }

  .rc-menu-item,
  .rc-menu-submenu-title {
    padding: 0 20px 10px 0;

    a {
      padding: 0 0 10px 0;
    }
  }

  .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 0 10px 10px 10px;
    cursor: pointer;
  }

  .rc-menu-submenu-active > .rc-menu-submenu-title {
    background-color: transparent;
  }

  .rc-menu-submenu-active:not(.rc-menu-submenu-selected)
    > .rc-menu-submenu-title {
    &:hover {
      box-shadow: inset 0 -2px $brand-secondary-light;
    }
  }

  .rc-menu-submenu-selected {
    background-color: transparent;
  }

  .rc-menu-submenu-selected > .rc-menu-submenu-title {
    box-shadow: inset 0 -2px $brand-secondary;
    color: $brand-secondary;
  }
}

.panel-menu__popup {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.18);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  border-radius: 0.25rem;
  z-index: 1050;

  &.rc-menu-submenu-hidden {
    display: none;
  }

  .rc-menu {
    padding: 0.5rem 0;
    margin: 0;
    color: #495057;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow: none;
  }

  .rc-menu-item {
    padding: 0;

    a {
      display: block;
      width: 100%;
      padding: 0.35rem 1.5rem;
      clear: both;
      font-weight: 400;
      color: $brand-secondary;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;

      &:hover,
      &:focus {
        color: $brand-secondary-dark;
        text-decoration: none;
        background-color: $brand-secondary-light;
      }
    }
  }

  .rc-menu-item-active,
  .rc-menu-submenu-active > .rc-menu-submenu-title {
    background-color: $brand-secondary-light;
  }

  .rc-menu-item-selected {
    background-color: $brand-secondary-light;
  }
}
