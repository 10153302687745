.data-slider {
  width: 100%;

  &__slide {
    margin-bottom: 0.5rem;
  }

  &__title {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0.25rem;

    @media (max-width: 767.98px) {
      font-size: 17px;
    }
  }

  &__nav {
  }
}

.data-slider-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
  }
}
