.googleInstructions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.googleInstructionsTextBlock {
  flex: 1;
}

.media {
  max-height: 180px;
  max-width: 280px;
}