.book-picked-photo {
  position: relative;
  height: 200px;
  margin-bottom: 0.5rem;

  &__img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top;
  }

  &__actions {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__badges-block {
    position: absolute;
    top: 0.75rem;
    left: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__order-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-secondary;
    border: none;
    padding: 0;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    line-height: normal;
    color: $custom-white;
  }

  &__btn {
    &_is-cover {
      background: $brand-secondary;
      color: $custom-white;
    }
  }
}
