.chat-info-view {
  .chat-members-section {
    .chat-members {
      &__block {
      }
    }

    .chat-members-item {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      border-bottom: 1px solid $custom-gray-300;

      &__avatar {
        margin-right: 1rem;
      }

      &__content {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &__info {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &__end-block {
        margin-left: 1rem;
      }
    }
  }
}
