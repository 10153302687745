.model-upload-avatar {
  display: flex;

  &__block {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    img {
      max-width: 100%;
      height: auto;
      object-position: top;
    }
  }

  &__select-block {
    display: flex;
    margin-bottom: 1rem;

    .avatar-select-wrap {
      display: flex;
    }
  }
}