.panel-topbar {
  &__nav {
    display: flex;

    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }

  &__menu {
    flex: 1;
    overflow: hidden;
  }

  &__actions {
    margin-left: 1rem;

    @media (max-width: 575.98px) {
      margin: 1rem 0 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}
