@import "../../../../../assets/scss/theme";
@import "src/assets/scss/giulia-variables";

.root {
}

.button {
  position: relative;
  border-radius: 50%;
  padding: 0;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $brand-beige;
  border: 2px solid $custom-anthracite-gray;
}

.targetClose {
  border-radius: 50%;
  position: absolute;
  top: 0;

}

.img {
  border-radius: 50%;
}

.popoverRoot {
  top: -8px !important;
  z-index: 10000;
  max-width: 320px;
}

.popoverInner {
}

.popoverHeader {
  background-color: $brand-secondary;
}

.popoverBody {
  height: 320px;
  padding: 0;

  @media (min-height: 640px) {
    height: 440px;
  }

  @media (min-height: 850px) {
    height: 650px;
  }
}