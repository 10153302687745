.members-drawer-grid {
  .grid__col {
    width: calc(100% / 4);
    margin-bottom: 2rem;

    @media (max-width: 1699.98px) {
      width: calc(100% / 4);
    }

    @media (max-width: 1599.98px) {
      width: calc(100% / 4);
    }

    @media (max-width: 1140px) {
      width: calc(100% / 3);
    }

    @media (max-width: 767.98px) {
      width: calc(100% / 2);
    }

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .grid__item {
    height: 100%;
    display: flex;
  }
}

.members-overview-card {
  .media-overview-card__cover-block {
    position: relative;
    height: auto;
    padding-bottom: 0.5rem;

    .avatar-wrap {
      height: 336px;
    }
  }
}
