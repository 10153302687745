.model-photo-picker {
  .photo-picker__photo-block {
    width: 160px;
    height: 240px;

    @media (max-width: 575.98px) {
      width: 100%;
      height: auto;
    }
  }
}

.model-photo-picker.photo-picker__selected {
  .photo-picker__photo-block {
    height: auto;
  }
}