.chart-card {
  background: $brand-neutral;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 8px;
    .space {
      flex-grow: 1;
    }
  }
  &__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    font-size: 16px;
    font-weight: 700;
    flex-shrink: 1;

    @media (max-width: 767.98px) {
      font-size: 18px;
    }
  }
  &__permission {
  }
  &__controller {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__body {
    background-color: $brand-neutral;
    display: flex;
    flex-direction: column;
  }
}
