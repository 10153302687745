$gutter: 8;

.model-photos-selection-grid {
  .grid-item,
  .grid-sizer {
    margin-bottom: #{$gutter}px; // gutter
    width: masonry-grid-item-width(8, $gutter);

    @media (max-width: 1399.98px) {
      width: masonry-grid-item-width(6, $gutter);
    }

    @media (max-width: 991.98px) {
      width: masonry-grid-item-width(4, $gutter);
    }

    @media (max-width: 767.98px) {
      width: masonry-grid-item-width(3, $gutter);
    }

    @media (max-width: 575.98px) {
      width: masonry-grid-item-width(2, $gutter);
    }
  }
}

.model-photos-selection-photo-card {
  width: 100%;

  &_selected {
    .model-photos-selection-photo-card__img-cover {
      background: rgba(48, 48, 48, 0.5);
    }
  }

  &__photo-block {
    position: relative;
    display: flex;
    overflow: hidden;
  }

  &__photo {
    width: 100%;
  }

  &__img-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  &__actions-block {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
