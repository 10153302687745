.groups-drawer-grid {
  .grid__col {
    width: calc(100% / 4);
    margin-bottom: 1.5rem;

    @media (max-width: 1699.98px) {
      width: calc(100% / 4);
    }

    @media (max-width: 1599.98px) {
      width: calc(100% / 3);
    }
    @media (max-width: 1140px) {
      width: calc(100% / 2);
    }
    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .grid__item {
    height: 100%;
    display: flex;
  }
}

.group-overview-card {
  background: $brand-neutral;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  padding: 1rem;
  width: 100%;
  display: flex;
  .space {
    flex-grow: 1;
  }
  &__info-block {
    align-self: center;
  }
  &__title {
    font-weight: 700;
    font-size: 16px;
  }
  &__type {
  }
  &__total-count {
  }

  &__actions-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
