.model-panel-statement {
  padding-bottom: 60px;

  .statement-table-total {
  }

  .statement-payment-submit {
    &__btn {
      min-width: 5.5rem;
    }
  }

  @media print {
    .info-card {
      box-shadow: none;
      padding: 0;
      background: $custom-white;
    }

    table {
      thead {
        background-color: $custom-white;
      }

      th {
        color: $custom-black;
      }
    }
  }
}
