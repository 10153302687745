.models-panel {
}

.models-panel-grid {
  .grid__col {
    width: calc(100% / 8);
    margin-bottom: 1.5rem;

    @media (max-width: 2800.98px) {
      width: calc(100% / 7);
    }

    @media (max-width: 2400.98px) {
      width: calc(100% / 6);
    }

    @media (max-width: 2200.98px) {
      width: calc(100% / 5);
    }

    @media (max-width: 1800.98px) {
      width: calc(100% / 4);
    }

    @media (max-width: 1599.98px) {
      width: calc(100% / 3);
    }

    @media (max-width: 767.98px) {
      width: calc(100% / 2);
    }

    @media (max-width: 500.98px) {
      width: 100%;
    }
  }

  .grid__item {
    height: 100%;
    display: flex;
  }
}

.models-panel-card-overview {
  background: $brand-neutral;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  padding: 1rem;
  width: 100%;

  &__avatar-block {
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.125rem;
    position: relative;
    cursor: pointer;
    user-select: none;

    .image-wrap {
      aspect-ratio: 1 / 1.6;
      @media (max-width: 575px) {
        aspect-ratio: 1 / 1;
      }
    }

    a {
      display: block;
    }
  }

  &__in-town-badge {
    position: absolute;
    bottom: 0.75rem;
    right: 0.5rem;
  }

  &__actions-block {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__avatar-placeholder-block {
    background-color: #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      height: auto;
    }
  }

  &__title-block {
    padding: 0.75rem 0;
    display: flex;
    justify-content: space-between;
  }

  &__title-name {
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
  }

  &__body-block {
    border-top: 1px solid #d1dad9;
    padding-top: 0.5rem;

    .info-list__label {
      font-weight: 500;
      width: 50px;
      white-space: nowrap;

      @media (max-width: 767.98px) {
        width: 60px;
      }
    }

    .info-list__block:last-of-type {
      .info-list__label {
        width: 70px;

        @media (max-width: 767.98px) {
          width: 80px;
        }
      }
    }

    .info-list__info:last-of-type {
      margin-right: 0.25rem;
    }

    .info-list__info {
      &_group {
        &:after {
          content: ", ";
        }
        &:last-child:after {
          content: "";
        }
      }
    }
  }
}
