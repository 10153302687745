.dnd-card {
  background: $brand-neutral;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;

  &__draggable-btn {
    background-color: $brand-dark;
    padding: 8px 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    &_icon-title {
      display: flex;
      padding: 8px 0;
    }

    &_icon {
      margin-right: 15px;
    }

    &_title {
      color: $custom-white;
      font-weight: 500;
    }
    &_controllers {
      display: flex;
      align-items: baseline;
      gap: 2px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }
}
