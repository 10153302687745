.chat-conversation .ps__rail-y {
  z-index: 1;
}

.chat__attachment-icon {
  position: relative;
  top: 2px;
}

.chat-list {
  &__item {
  }

  .chat-item-name {
    line-height: 1.2;
  }

  &__item-end-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 41px;

    @media (max-width: 767.98px) {
      height: 47px;
    }
  }
}

.chat__room-empty-block {
  min-height: 665px;
}

.user-chat-nav .dropdown .nav-btn {
  height: 40px;
  width: 40px;
  line-height: 40px;
  box-shadow: none;
  padding: 0;
  font-size: 16px;
  background-color: $custom-white;
  border-radius: 50%;
  border-color: transparent;
}

.chat-room__header {
  border-bottom: 1px solid $custom-gray-300;
}

.chat-conversation {
  .chat-day-title {
    display: flex;
    justify-content: center;

    &:before {
      content: none;
    }

    &__wrap {
    }

    .title {
      background-color: $brand-secondary-dark;
      color: $custom-white;
      font-size: 12px;
    }
  }

  .right .conversation-list .ctext-wrap,
  .conversation-list .ctext-wrap {
    background-color: $custom-lavender;
  }

  .conversation-list {
    display: flex;
  }
}

.chat-room__entry-toolbar {
  .chat-input-section {
    border-top: 1px solid $custom-gray-300;
  }

  .chat-attachments-section {
    border-top: 1px solid $custom-gray-300;

    .files-uploader-field {
      margin: 0;

      &__files-block {
        margin: 0;
      }

      &__file-col {
        padding: 0;
      }
    }

    .files-uploader-field__file-col {
      width: 100%;
    }

    .attached-file-card {
      border: 1px solid $custom-gray-300 !important;
    }
  }
}

.chat-contacts {
  .chat-list {
    li a {
      line-height: 1.2;
    }
  }
}