.client-statement {
  padding-bottom: 60px;

  @media print {
    .info-card {
      box-shadow: none;
      padding: 0;
      background: $custom-white;
    }

    table {
      thead {
        background-color: $custom-white;
      }

      th {
        color: $custom-black;
      }
    }
  }
}
