.models-books-data-grid {
  .grid__row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .grid__col {
    width: calc(100% / 6);
    margin-bottom: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media (max-width: 1399.98px) {
      width: calc(100% / 2);
    }

    @media (max-width: 991.98px) {
      width: calc(100% / 3);
    }

    @media (max-width: 767.98px) {
      width: calc(100% / 2);
    }

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .grid__item {
    height: 100%;
    display: flex;
  }

  &.models-books-picker-grid {
    .grid__col {
      width: calc(100% / 4);

      @media (max-width: 1399.98px) {
        width: calc(100% / 2);
      }

      @media (max-width: 575.98px) {
        width: 100%;
      }
    }
  }
}
