.logo-picker {
  @media (max-width: 768px) {
    max-width: 220px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    position: relative;
    img {
      max-width: 100%;
      height: auto;
      object-position: center;
    }
  }

  &__select-block {
    display: flex;
    margin-bottom: 1rem;
  }

  &__select-wrap {
    display: flex;
  }

  &__logo-block {
    display: flex;
    width: 153px;
    height: 153px;
    justify-content: center;
  }

  &__select-btn {
    align-self: center;
  }

  &__placeholder-block {
    background-color: $brand-light;
    border-radius: 0.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
    }
  }
  &__remove-btn {
    position: absolute;
    right: -30px;
  }
}
