.billing-portal-invoices {
  .billing-invoices-grid {
    .grid__col {
      width: calc(100% / 4);
      margin-bottom: 1.5rem;

      @media (max-width: 1699.98px) {
        width: calc(100% / 4);
      }

      @media (max-width: 1599.98px) {
        width: calc(100% / 3);
      }

      @media (max-width: 767.98px) {
        width: calc(100% / 2);
      }

      @media (max-width: 575.98px) {
        width: 100%;
      }
    }

    .grid__item {
      height: 100%;
      display: flex;
    }
  }

  .billing-invoice-card-overview {
    background: $brand-neutral;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.125rem;
    padding: 1rem;
    width: 100%;
  }
}

.billing-portal-invoice-details {
  .invoice-details-card {
    background: $brand-neutral;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.125rem;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media print {
      box-shadow: none;
      padding: 0;
      background: $custom-white;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__header-number {
      font-size: 16px;

      @media (max-width: 767.98px) {
        font-size: 18px;
      }
    }

    &__summary-table {
      @media print {
        thead {
          background-color: $custom-white;
        }
        th {
          color: $custom-black;
        }
      }
    }
  }
}
