.calendar-event-category {
  padding: 0.25rem;
  width: 100%;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 767.98px) {
    font-size: 16px;
    line-height: 16px;
  }
}
