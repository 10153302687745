$today-bg-color: #f0f0f0;
$dayWidthSm: 100px;

.calendar {
  .fc .fc-toolbar {
    @media (max-width: 767.98px) {
      display: block !important;
      text-align: center;
    }

    .fc-toolbar-chunk .btn-group {
      @media (max-width: 575.98px) {
        width: 100%;
      }
    }
  }

  .fc .fc-col-header-cell.fc-day.fc-day-today {
    background-color: $today-bg-color;
    //border-bottom: 1px solid $today-bg-color;
    //border-right: 1px solid $today-bg-color;
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: $today-bg-color;
    //border: 1px solid $today-bg-color;
  }

  .fc .fc-col-header-cell-cushion {
    font-size: 14.42px;
    padding: 7px 4px;
  }

  .fc .fc-toolbar-title {
    @media (max-width: 767.98px) {
      font-size: 16px;
      margin: 1rem 0;
    }
  }

  .fc .fc-more-popover {
    z-index: 1000;
  }

  .fc .fc-more-popover .fc-popover-body {
    max-height: 500px;
    overflow-y: auto;

    @media (max-width: 767.98px) {
      max-height: 196px;
      overflow-y: auto;
    }
  }

  .fc-view-harness {
    // Horizontal scroll in mobile view
    @media (max-width: 767.98px) {
      overflow-x: scroll;
    }
  }

  .fc-dayGridWeek-view,
  .fc-dayGridMonth-view {
    &.fc-view {
      // Horizontal scroll in mobile view
      @media (max-width: 767.98px) {
        width: $dayWidthSm * 7;
      }
    }

    .fc-col-header-cell,
    .fc-daygrid-day {
      &.fc-day {
        @media (max-width: 767.98px) {
          width: $dayWidthSm;
        }
      }
    }
  }
}
