.label {
  display: flex;
  flex: 1;

  &__email {
    display: flex;
    flex: 1;
    font-weight: 600;
    font-size: 13px;
    word-break: break-all;
  }

  &__info {
    padding-left: 1rem;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__title {
    font-weight: 600;
    font-size: 13px;
  }

  &__subtitle {
    font-size: 12px;
  }
}