.file-uploading-card {
  width: 100%;
  background-color: white;
  border: 2px dashed $brand-secondary;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  .progress {
    background-color: $brand-neutral;
    &_bar {
      background-color: $brand-secondary;
    }
  }
  &_info-block {
    width: 80%;
    flex-shrink: 1;
  }
  &_name {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_actions-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &_error {
    margin-top: 2px;
    color: $brand-danger;
    font-size: 80%;
  }
}
