.calendar-event {
  &.fc-daygrid-event {
    white-space: normal;
  }

  .time {
    font-size: 11px;
  }
}

.calendar-event-content {
  display: flex;

  &__main {
    width: 100%;
    overflow: auto;
  }

  &__left-block {
    display: flex;
    flex-direction: column;
    margin-right: 0.25rem;
  }

  &__done-block {
    font-size: 18px;
    line-height: 18px;
    margin-top: auto;
  }

  &__right-block {
    display: flex;
    flex-direction: column;
    margin-left: 0.25rem;
  }

  &__priority-block {
    font-size: 10px;
    padding: 3px 3px 0 0;
    font-weight: bold;
  }

  &__close-block {
    font-size: 18px;
    line-height: 18px;
    margin-top: auto;
  }
}
