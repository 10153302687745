.posts-calendar-toolbar {
  display: flex;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  gap: 1rem;

  .post-category {
    padding: 0.25rem 0.5rem;
    min-width: 160px;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 767.98px) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .space {
    flex-grow: 1;
  }
}
