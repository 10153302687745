.sidebar-drawer {
  &.drawer {
    z-index: 1050;
  }

  .drawer-content-wrapper {
    width: $sidebar-width;
    background-color: $panel-sidebar-bg;
  }

  .drawer-body {
    padding: 0;
  }

  .sidebar-drawer-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$header-height});
  }
}

.vertical-collpsed .sidebar-drawer {
  .navbar-brand-box {
    @media (max-width: 991.98px) {
      width: auto !important;
    }
  }
}

@media (max-width: 991.98px) {
  body[data-sidebar="primary-green"] .sidebar-drawer .navbar-brand-box {
    background: $panel-sidebar-bg;
  }
}
