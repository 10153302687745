.model-panel-video {
  .model-panel-videos-grid {
    .grid__row {
      margin-left: -1rem;
      margin-right: -1rem;
    }

    .grid__col {
      width: calc(100% / 3);
      margin-bottom: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;

      @media (max-width: 1399.98px) {
        width: calc(100% / 2);
      }

      @media (max-width: 575.98px) {
        width: 100%;
      }
    }

    .grid__item {
      height: 100%;
      display: flex;
    }
  }
}