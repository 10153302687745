.calendar-event-select-models-control {
  .selected-list-control__list-wrap {
    height: 123px;

    @media (max-width: 767.98px) {
      height: 135px;
    }
  }
}

.calendar-event-model-action-priority-menu {
  .popover {
    max-width: 100%;
  }

  .order-btn {
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }

    height: 24px;
    min-width: 30px;

    @media (max-width: 767.98px) {
      height: 28px;
    }

    &.active {
      &.disabled {
        background-color: $brand-secondary;
        color: $custom-white;
      }
    }
  }
}

.calendar-event-selected-model {
  flex-wrap: wrap;

  .selected-list-control__item-text {
    display: flex;
    word-break: initial;
    white-space: nowrap;
  }

  .event-mark-type {
    display: inline-block;
    background-color: $brand-secondary;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px $custom-white;
    position: relative;
    top: 4px;

    @media (max-width: 767.98px) {
      top: 6px;
    }
  }

  .model-action-bar {
    display: flex;

    &__item {
      &:not(:first-of-type) {
        margin-left: 0.5rem;
      }
    }
  }
}
