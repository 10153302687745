.book-overview-card {
  width: 100%;

  &__cover-block {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 500px;
    padding-bottom: 0.5rem;
  }

  &__cover-wrapper {
    width: 100%;
  }

  &__cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  &__cover-placeholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__photos-amount-badge-block {
    position: absolute;
    top: 0.75rem;
    left: 0.5rem;
  }

  &__photos-amount-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-secondary;
    border: none;
    padding: 0;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: normal;
    font-weight: 600;
    font-size: 16px;
    color: $custom-white;
  }

  &__actions-block {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__actions-block-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__header-panel {
  }

  &__title-block {
    padding: 0.5rem 0 0;
  }

  &__title {
    font-weight: 700;
    font-size: 15px;
  }

  &__body {
    padding: 0.5rem 0 0;
  }

  &__description {
    .read-more-expand__text {
      font-size: 13px;
    }
  }

  &_size {
    &_sm {
      .book-overview-card__cover-block {
        height: 272px;
      }

      .book-overview-card__description {
        .read-more-expand__text {
          font-size: 11px;
        }
      }
    }
  }
}
