.website-template-data-grid {
  .grid__row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .grid__col {
    width: calc(100% / 2);
    margin-bottom: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media (max-width: 767.98px) {
      width: 100%;
    }
  }

  .grid__item {
    height: 100%;
    display: flex;
  }
}
