.payment-card-select {
  .payment-card-select-label {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    &__number {
      min-width: 250px;
    }

    &__date {
      min-width: 170px;
    }

    &__payment-system {
    }
  }

  .select__control {
    .payment-card-select-label {
      &__date {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &__payment-system {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}
