.calendar-event-invoice-view {
}

.calendar-event-invoice-view-card {
  .card-block {
    padding: 0.5rem;
    border: 1px solid $gray-300;
    border-radius: 0.125rem;
  }

  // Logo
  .invoice-card-logo {
    max-width: 50%;
    margin-bottom: 1rem;

    img {
      max-width: 100%;
      max-height: 100px;
    }
  }

  // === //

  // Info
  .invoice-card-info {
    display: flex;

    @media (max-width: 767.98px) {
      flex-direction: column;

      @media print {
        flex-direction: row;
      }
    }

    &__contact-block {
      display: flex;
      flex: 1;
      margin-right: 1rem;

      @media (max-width: 767.98px) {
        margin: 0;

        @media print {
          margin-right: 1rem;
        }
      }
    }

    &__client-block {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 1rem;

      @media (max-width: 767.98px) {
        margin: 1rem 0 0;

        @media print {
          margin: 0;
          margin-left: 1rem;
        }
      }
    }

    &__contact-info {
      white-space: pre-wrap;
    }

    &__client-info {
      white-space: pre-wrap;
      font-weight: $fw-medium;
    }

    &__client-vat {
      margin-top: 0.5rem;
    }
  }

  // === //

  // Details
  .invoice-card-invoice-details {
    margin-top: 1rem;

    &__info {
      display: flex;

      @media (max-width: 767.98px) {
        flex-direction: column;

        @media print {
          flex-direction: row;
        }
      }
    }

    &__info-item {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      &:not(:last-of-type) {
        margin-right: 1rem;

        @media (max-width: 767.98px) {
          margin-right: 0;

          @media print {
            margin-right: 1rem;
          }
        }
      }
    }

    &__info-label {
      font-weight: $fw-medium;
      margin-right: 1rem;
    }

    &__payment {
      margin-top: 1rem;
      display: flex;
      flex: 1;

      @media (max-width: 767.98px) {
        flex-direction: column;

        @media print {
          flex-direction: row;
        }
      }
    }

    &__payment-terms-block {
      flex: 1;
      margin-right: 1rem;

      @media (max-width: 767.98px) {
        margin: 0;

        @media print {
          margin-right: 1rem;
        }
      }
    }

    &__payment-bank-block {
      flex: 2;

      @media (max-width: 767.98px) {
        flex: 1;

        @media print {
          flex: 2;
        }
      }
    }

    &__payment-terms {
      white-space: pre-wrap;
    }
  }

  // === //

  // Table
  .invoice-card-expenses-table {
    margin-top: 1rem;

    table tbody {
      background-color: $white !important;
    }

    table {
      @media print {
        thead {
          background-color: $custom-white;
        }
        th {
          color: $custom-black;
        }
      }
    }
  }

  // === //

  // Summary
  .invoice-card-summary {
    .table-bordered th,
    .table-bordered td {
      border-color: #d7dfde;
    }

    table {
      border-spacing: 0;
      border-collapse: separate;
    }

    table tr:first-child th:first-child,
    table tr:first-child td:first-child {
      border-top-left-radius: 0.125rem;
    }

    table tr:first-child th:last-child,
    table tr:first-child td:last-child {
      border-top-right-radius: 0.125rem;
    }

    table tr:last-child th:first-child,
    table tr:last-child td:first-child {
      border-bottom-left-radius: 0.125rem;
    }

    table tr:last-child th:last-child,
    table tr:last-child td:last-child {
      border-bottom-right-radius: 0.125rem;
    }
  }

  // === //

  // Notes
  .invoice-card-notes {
    min-height: 80px;

    &__total {
      margin-left: 2rem;

      @media (max-width: 767.98px) {
        margin: 0.5rem;
      }
    }
  }

  // === //
}
