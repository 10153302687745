.grid {
    position: relative;
}

.grid__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
}

.grid__col {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.grid__item {
}