.drawer-block {
  &.drawer {
    z-index: 1049;
  }

  .drawer-content-wrapper {
    background-color: $custom-white;
  }

  .drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
  }

  .drawer-header {
    padding: 2rem 3rem 1rem 2rem;
    position: relative;
    display: flex;

    &__title {
      flex: 1;
    }

    .close-wrap {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .drawer-body {
    flex-grow: 1;
    padding: 2rem;
  }
}

.drawer-container {
  .drawer-content-wrapper {
    width: 80%;
  }

  .drawer-body {
    padding: 1rem 1.25rem;
  }
}

.drawer-separator {
  background-color: $brand-light;
  opacity: 1;
  margin: 2.5rem 0;

  &_form {
    margin: 1.5rem 0;
  }

  &.bg-gray-light {
    background-color: $gray-300;
  }
}

.drawer_nested {
  .drawer-content-wrapper {
    width: 70%;
  }
}
