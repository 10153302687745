.share-box {

  &__title {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 22px;
  }

  &__icons-container{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}