.form-text {
  display: block;
}

// Highlight checkbox
.form-checkbox_highlight {
  color: $custom-blue-highlight;

  .form-check-input {
    border-color: $custom-blue-highlight;

    &:checked {
      background-color: $custom-blue-highlight;
      border-color: $custom-blue-highlight;
    }
  }
}