.agencies-panel-grid {
  .grid__col {
    width: calc(100% / 4);
    margin-bottom: 1.5rem;

    @media (max-width: 1699.98px) {
      width: calc(100% / 4);
    }

    @media (max-width: 1599.98px) {
      width: calc(100% / 3);
    }

    @media (max-width: 767.98px) {
      width: calc(100% / 2);
    }

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .grid__item {
    height: 100%;
    display: flex;
  }
}

.agencies-panel-card-overview {
  background: $brand-neutral;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  padding: 1rem;
  width: 100%;

  &__title-block {
    padding: 0.75rem 0;
    display: flex;
    justify-content: space-between;
  }

  &__title-name {
    font-weight: 700;
    font-size: 16px;

    @media (max-width: 767.98px) {
      font-size: 18px;
    }

    a {
      color: #303030;

      &:hover,
      &:focus {
        color: #303030;
      }
    }
  }
}
