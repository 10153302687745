.recipients-list-control-wrapper {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.recipient-option-label {
  display: flex;
  flex: 1;

  &__email {
    display: flex;
    flex: 1;
    font-weight: 600;
    font-size: 13px;
    word-break: break-all;
  }

  &__info {
    padding-left: 1rem;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__owner-name {
    font-weight: 600;
    font-size: 13px;
  }

  &__contact-name {
    font-size: 12px;
  }
}
